
.tum-hints-showHint a {
	font-size: 12px;
	color: #ca1f2f !important;
}

.tum-hints-hintBlock {
	display: none;
}

	.tum-hints-hintBlock.show {
		display: block;
		background-color: #FFF8B6;
	}

	.tum-hints-hintBlock .tum-hints-hintIcon {
		color: unset;
	}

.tum-hints-hintBorder {
	border: 2px solid #f68500;
	padding: 5px;
}
