/* icons */
.tu-icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	min-width: 1em;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.tu-icon.icon-12 {
	width: 12px;
	min-width: 12px;
	height: 12px;
}

.tu-icon.icon-16 {
	width: 16px;
	min-width: 16px;
	height: 16px;
}

.tu-icon.icon-18 {
	width: 18px;
	min-width:18px;
	height: 18px;
}

.tu-icon.icon-22 {
	width: 22px;
	min-width: 22px;
	height: 22px;
}

.tu-icon.icon-backspace { background-image: url("../images/icons/svg/backspace.svg"); width: 32px; background-position: cover; }
.tu-icon.icon-bars { background-image: url("../images/icons/bars.png"); background-position-y: 3px; }
.tu-icon.icon-cart { background-image: url("../images/icons/cart.png"); }
.tu-icon.icon-cart2 { background-image: url("../images/icons/svg/cart.svg"); }
.tu-icon.icon-cart-bold { background-image: url("../images/icons/svg/cart-bold.svg"); }
.tu-icon.icon-catalog { background-image: url("../images/icons/svg/catalog1.svg"); }
.tu-icon.icon-catalog2 { background-image: url("../images/icons/svg/catalog.svg"); }
.tu-icon.icon-cross { background-image: url("../images/icons/cross.png"); }
.tu-icon.icon-history { background-image: url("../images/icons/history.png"); }
.tu-icon.icon-location { background-image: url("../images/icons/location.png"); }
.tu-icon.icon-location-black { background-image: url("../images/icons/location-black.png"); }
.tu-icon.icon-org { background-image: url("../images/icons/org.png"); }
.tu-icon.icon-org-black { background-image: url("../images/icons/org-black.png"); }
.tu-icon.icon-shopping-bag { background-image: url("../images/icons/shopping-bag.png"); }
.tu-icon.icon-shopping-bag-black { background-image: url("../images/icons/shopping-bag-black.png"); }
.tu-icon.icon-phone { background-image: url("../images/icons/phone.png"); }
.tu-icon.icon-phone-black { background-image: url("../images/icons/phone-black.png"); }
.tu-icon.icon-search { background-image: url("../images/icons/search.png"); }
.tu-icon.icon-search2 { background-image: url("../images/icons/svg/search.svg"); }
.tu-icon.icon-search-grey { background-image: url("../images/icons/search-grey.png"); }

.tu-icon.icon-map-pin { background-image: url("../images/icons/map-pin.png"); }
.tu-icon.icon-map-pin-black { background-image: url("../images/icons/map-pin-black.png"); }
.tu-icon.icon-view-large { background-image: url("../images/icons/svg/view-large.svg"); }
.tu-icon.icon-view-grid { background-image: url("../images/icons/svg/view-grid.svg"); }
.tu-icon.icon-view-list { background-image: url("../images/icons/svg/view-list.svg"); }
.tu-icon.icon-settings { background-image: url("../images/icons/svg/settings.svg"); }

.tu-icon.icon-searchByAny { background-image: url("../images/icons/search-grey.png"); }
.tu-icon.icon-searchByProduct { background-image: url("../images/icons/shopping-bag-black.png"); }
.tu-icon.icon-searchByFirm { background-image: url("../images/icons/org-black.png"); }
.tu-icon.icon-searchByAddress { background-image: url("../images/icons/location-black.png"); }
.tu-icon.icon-searchByContacts { background-image: url("../images/icons/phone-black.png"); }

.tu-icon.icon-fav { background-image: url("../images/icons/svg/fav.svg"); }
.tu-icon.icon-fav-regular { background-image: url("../images/icons/svg/fav-regular.svg"); }
.tu-icon.icon-fav-regular2 { background-image: url("../images/icons/svg/fav-regular2.svg"); }
.tu-icon.icon-fav-fill { background-image: url("../images/icons/svg/fav-fill.svg"); }
.tu-icon.icon-rating { background-image: url("../images/icons/svg/rating.svg"); }
.tu-icon.icon-rating-fill { background-image: url("../images/icons/svg/rating-fill.svg"); }
.tu-icon.icon-profile { background-image: url("../images/icons/svg/profile.svg"); }
.tu-icon.icon-profile-regular { background-image: url("../images/icons/svg/profile-regular.svg"); }
.tu-icon.icon-check { background-image: url("../images/icons/svg/check.svg"); }
.tu-icon.icon-clock { background-image: url("../images/icons/svg/clock.svg"); }
.tu-icon.icon-phone { background-image: url("../images/icons/svg/phone.svg"); }
.tu-icon.icon-point { background-image: url("../images/icons/svg/point.svg"); }
.tu-icon.icon-contacts { background-image: url("../images/icons/svg/contacts.svg"); }
.tu-icon.icon-curved-arrow-up-right { background-image: url("../images/icons/svg/curved-arrow-up-right.svg"); }
.tu-icon.icon-dots-horizontal { background-image: url("../images/icons/svg/dots-horizontal.svg"); }
.tu-icon.icon-hamburger { background-image: url("../images/icons/svg/hamburger.svg"); }


.tu-icon.icon-search-white {
    background-image: url("../images/icons/search-white.png");
}

.tu-icon.icon-search-catalog {
    background-image: url("../images/icons/search-catalog.png");
}

.tu-icon.icon-search-catalog1 {
    background-image: url("../images/icons/search-catalog1.png");
}

.tu-icon.icon-search-catalog4 {
    background-image: url("../images/icons/search-catalog4.png");
}

.tu-icon.icon-search-catalog2 {
    background-image: url("../images/icons/search-catalog2.png");
}

.tu-icon.icon-search-catalog3 {
    background-image: url("../images/icons/search-catalog3.png");
}

.tu-icon.icon-row-left-red {
    background-image: url("../images/icons/row-left-red.png");
}

.tu-icon.icon-row-right-red {
    background-image: url("../images/icons/row-right-red.png");
}

.tu-icon.icon-row-left-black {
    background-image: url("../images/icons/row-left-black.png");
}

.tu-icon.icon-row-right-black {
    background-image: url("../images/icons/row-right-black.png");
}

.active .tu-icon.icon-searchByAny { background-image: url("../images/icons/search.png"); }
.active .tu-icon.icon-searchByProduct { background-image: url("../images/icons/shopping-bag.png"); }
.active .tu-icon.icon-searchByFirm { background-image: url("../images/icons/org.png"); }
.active .tu-icon.icon-searchByAddress { background-image: url("../images/icons/location.png"); }
.active .tu-icon.icon-searchByContacts { background-image: url("../images/icons/phone.png"); }

.fa-plus18 {
    text-align: center;
    font-size: 60%;
    font-weight: bold;
}

.fa-plus18:before {
    content: "18";
}

.fa-stack.fa-plus18-wrapper {
	width: 1em;
}

.fa-stack.fa-plus18-wrapper .fa-plus18-plus {
	font-size: 110%;
	top: -6px;
	left: 6px;
	z-index: 0;
	-webkit-text-stroke: 1px #ca1f2f;
	color: white;
	font-weight: bold;
}

/* general */
.tum-bg-grey {
	background-color: #f6f6f6;
}

.tum-grey-4 {
	color: #787878;
}

.gap-1 {
	gap: 0.25rem;
}

.gap-2 {
	gap: 0.5rem;
}

.gap-3 {
	gap: 1rem;
}

@media (min-width: 768px) {
	.gap-md-1 {
		gap: 0.25rem;
	}
	
	.gap-md-2 {
		gap: 0.5rem;
	}

	.gap-md-3 {
		gap: 1rem;
	}
}

.column-gap-1 {
	column-gap: 0.25rem;
}

.column-gap-2 {
	column-gap: 0.5rem;
}

.column-gap-3 {
	column-gap: 1rem;
}

.fa-lg-2 {
	font-size: 22px;
}

.tum-border-dark-red {
	border-color: #ca1f2f;
}

.tum-border-green {
	border-color: green;
}

.tum-bg-grey5 {
	background-color: #ecf0f3 !important;
}

.tum-border-grey5 {
	border-color: #ecf0f3 !important;
}

.noscroll {
	overflow: hidden;
}

.line-height-1 {
	line-height: 1em;
}

.fs-10 {
	font-size: 10px !important;
}

.fs-12 {
	font-size: 12px !important;
}

.fs-13 {
	font-size: 13px !important;
}

.fs-14 {
	font-size: 14px !important;
}

.fs-16 {
	font-size: 16px !important;
}

.fs-24 {
	font-size: 24px !important;
}

.fs-05rem {
	font-size: 0.5rem !important;
}

.fs-06rem {
	font-size: 0.6rem !important;
}

.fs-07rem {
	font-size: 0.7rem !important;
}

.fs-08rem {
	font-size: 0.8rem !important;
}

.fs-09rem {
	font-size: 0.9rem !important;
}

.fs-1rem {
	font-size: 1rem !important;
}

.fs-11rem {
	font-size: 1.1rem !important;
}

.fs-12rem {
	font-size: 1.2rem !important;
}

.font-size-small {
	font-size: 0.875rem !important;
}

.font-size-normal {
	font-size: 1rem !important;
}

.font-size-large {
	font-size: 1.125rem !important;
}

.bg-pink {
	background-color: #ffe6e8 !important;
}

.rounded-5 {
	border-radius: 5px !important;
}

.rounded-10 {
	border-radius: 10px !important;
}

.text-decoration-dotted {
	text-decoration: underline dotted;
}

/* обрезает текст до двух строк */
.text-truncate-2 {
	display: -webkit-box;
	line-clamp: 2;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.text-truncate-15 {
	display: -webkit-box;
	line-clamp: 15;
	-webkit-line-clamp: 15;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.text-wrap {
	white-space: normal !important;
}

.btn.btn-tum-dark-red {
    color: #fff;
    background: #ca1f2f;
}

.btn.btn-tum-dark-red.underline > :first-child {
	border-bottom: 1px dashed #ffffff;
}

.btn.btn-tum-dark-red:hover {
    background: #a31825;
    color: #fff;
}

.btn.btn-tum-dark-red.disabled,
.btn.btn-tum-dark-red:disabled {
    opacity: 0.35;
}

.btn.btn-tum-lightgreen {
    color: black;
    background: #90ee90;
}

.btn.btn-tum-lightgreen:hover {
    color: #fff;
	background: #1cc88a;
}

.btn.btn-tum-lightgreen.disabled,
.btn.btn-tum-lightgreen:disabled {
    opacity: 0.35;
}


.btn.btn-tum-dark-red2 {
    color: #fff;
    background: #ca1f2f;
    border-color: #ca1f2f;
}

.btn.btn-tum-dark-red2:hover {
    background: #fff;
    color: #ca1f2f;
}

.btn.btn-tum-dark-red2.disabled,
.btn.btn-tum-dark-red2:disabled {
    opacity: 0.35;
}

.btn.btn-tum-white {
    color: #ca1f2f;
    background: #fff;
    border-color: #ca1f2f;
}

.btn.btn-tum-white:hover {
    background: #ca1f2f;
    color: #fff;
}

.btn.btn-tum-white.disabled,
.btn.btn-tum-white:disabled {
    opacity: 0.35;
}

.btn.btn-tum-white-green {
    color: green;
    background: #fff;
    border-color: green;
}

.btn.btn-tum-white-green:hover {
    background: green;
    color: #fff;
}

.btn.btn-tum-white-green.disabled,
.btn.btn-tum-white-green:disabled {
    opacity: 0.35;
}

.btn.btn-tum-yellow {
    background: #e3c113;
    color: white;
}

.tum-grey {
    color: #555555 !important;
}

.tum-light-grey {
    color: #ccc !important;
}

.tum-light-grey2 {
    color: #e0e0e0 !important;
}

.tum-grey2 {
    color: #cfd2d5 !important;
}

.tum-grey3 {
    color: #888 !important;
}

.tum-black {
    color: #212529 !important;
}

.tum-dark-red {
    color: #ca1f2f !important;
}

.tum-border-dark-red {
    border-color: #ca1f2f !important;
}

.tum-dark-red-light {
    color: #dc3545;
}

.tum-pale-red {
    color: #c6898f;
}

.tum-bg-dark-red {
    background-color: #ca1f2f;
}

.tum-bg-dark-red-light {
    background-color: #dc3545;
}

.tum-green {
    color: green !important;
}

.tum-light-green {
    color: #7fd13b !important;
}

.tum-light-green2 {
    color: #94e5b7 !important;
}

.tum-light {
    background-color: #ffffe9;
}

.tum-blue {
    color: #007bff !important;
}

.tum-light-blue {
    color: #2F80ED !important;
}

.tum-bg-light-blue {
    background-color: #2F80ED !important;
}

.tum-border-blue {
    border-color: #007bff;
}

.tum-yellow {
    color: #e3c113;
}

.tum-pink {
    color: pink;
}

.tum-pink2 {
    color: #ffa6aa;
}

.tum-bg-pink {
    background-color: pink;
}

.tum-border-pink {
    border: 1px solid pink;
    padding: 5px;
}



.tum-border-3 {
    border-top-width: 3px;
}

.tum-bg-transparent {
    background-color: transparent!important;
}

.tum-bg-attention {
    background-color: #f4d1d5;
}

.tum-box-attention {
    box-shadow: 0 1px 10px 5px rgba(255, 0, 0, 0.45) !important;
}

.tum-rotate-45 {
	transform: rotate(45deg);
}

/* header */
.topbar {
	background-color: #f6f6f6;
	z-index: var(--z-index-bars);
}

.topbar-left {
	display: flex;
	align-items: center;
	gap: 0.5em;
}

.topbar-right {
	display: flex;
	align-items: center;
}

.topbar-right > a:hover {
	text-decoration: none;
}

.searchWidget-container .restrict-msg {
	position: absolute;
	margin-top: 0.15rem;
}

.restrict-msg .number-circle {
	font-size: 0.9em;
	font-weight: bold;
	width: 1.6em;
	height: 1.6em;
}

.adulthood-modal .modal-footer .modal-buttons {
	padding: 0rem 66px;
	padding-top: 1rem;
}

.adulthood-modal .hide-restrict-items {
	text-align: left;
	padding-left: 66px;
}

.adulthood-modal .text-wrap {
	text-align: center;
}

.adulthood-modal .modal-footer .btn-primary, .adulthood-modal .modal-footer .btn-default {
	background-color: #212529;
	border-color: #212529;
	color: white !important;
}

.adulthood-modal .modal-footer .btn-primary {
	padding: 0.375rem 1rem;
}


@media (max-width: 767px) {
    .adulthood-modal .btn.btn-default {
        padding: 0.375rem 0.75rem !important;
    }
}

.number-circle {
	width: 1.5em;
	height: 1.5em;
	color: white;
	background-color: #ca1f2f;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 0.65em;
}

@media (max-width: 767px) {
	.number-circle {
		font-size: 0.5em;
	}
}

.topbar-right .number-circle {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
}

.topbar-userPhoto {
	height: 20px;
	width: 20px;
}

.topbar-userPhoto img {
	border-radius: 50%;
	width: 100%;
	height: 100%;
}

.topbar-left > *, .topbar-right > * {
	position: relative;
}

.tum-navbar-cats {
	top: 45px;
}

.mob-menu, .mob-searchMenu {
	box-shadow: 0 0.8rem 1rem rgba(0, 0, 0, 0.25);
}

.topbar-openCatalog i {
	transition: 0.15s;
}

.topbar-openCatalog .icon-catalog-white {
	visibility: visible;
}

.topbar-openCatalog:hover .icon-catalog-white {
	visibility: hidden;
}

.topbar-openCatalog:hover .icon-catalog {
	visibility: visible;
}

.tg-connect-link {
	color: #229bd5;
	font-weight: 600; 
	display:inline-block;
}

.btn-details {
	background: #00000014; 
	border-radius: 10px;
	font-style: italic;
	display: inline-block;
	color: grey;
	font-size: .875em;
}

/* search */
.searchWidget {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: relative;
}

.searchWidget .focusArea {
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: 0.5rem;
	min-width: 0;
	position: relative;
}

.searchWidget input {
    flex-grow: 1;
    border: none;
    background: transparent;
}

.searchWidget .clearField {
    border-right: 1px solid #d1d1d1;
    padding-right: 2.2rem;
	display: none;
	position: absolute;
	right: 0;
}

.searchWidget .searchTypes-list {
    width: 100%;
    background: white;
    position: absolute;
    left: 0;
    top: 40px;
    border-top: none;
    box-shadow: 0 0.8rem 1rem rgba(0, 0, 0, 0.25) !important;
    list-style: none;
    padding-left: 0.5rem;
	z-index: 6;
}

.searchWidget .searchTypes-list li {
    padding: 0.5rem;
}

.searchWidget .searchTypes-list .searchType {
    color: black;
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.searchWidget .searchTypes-list .searchType.active {
    color: var(--color-primary);
}

.searchWidget .selectSearchType:focus {
	outline: none;
}

.searchWidget .searchTypeIcon {
    display: none;
}

.searchWidget .searchTypeName {
	display: flex;
	align-items: center;
	gap: 0.2rem;
}

.searchWidget .searchTypeName-text {
	display: inline-block;
}

.searchWidget .selectSearchType:hover {
	text-decoration: none;
}

.searchWidget .focusArea:focus-within .clearField {
	display: block;
}

.searchWidget .suggestions {
	box-shadow: 1px 8px 10px rgba(0,0,0,.7);
    z-index: 3;
    border-radius: 0 0 5px 5px;
	width: 100%;
	position: absolute;
	background: white;
	left: 0;
	top: 40px;
	border-top: none;
}

.searchWidget-under {
	position: absolute;
	bottom: -25px;
	left: 0px;
	width: 100%;
}

.searchSuggestionItem:focus, .searchSuggestionItem:focus-within {
	background-color: #eeeeee;
}

.tu-shadow {
	box-shadow: -5px 4px 8px rgba(0,0,0,.5);
}

/* editmenu */
.tu-editMenu .dropdown-toggle::after {
	display: none;
}

.tu-editMenu .dropdown-menu a {
	color: #212529;
}

.tu-editMenu .tum-readmore-0 {
	display: none;
	padding: 0 1.5rem;
}

.tu-editMenu .tum-readmore-0.show {
	display: block
}

.tu-editMenu .dropdown-item:focus, .tu-editMenu .dropdown-item:hover {
	background-color: #f6f6f6;
}

.header-floating {
	z-index: 1001;
	position: fixed;
	background-color: white;
	width: 100%;
	opacity: 0;
	transition: opacity 0.2s ease-in-out;
	pointer-events: none;
	box-shadow: 0px 0px 8px rgb(0 0 0 / 50%);
}

.header-floating.show {
	opacity: 1;
	pointer-events: initial;
}

.topbar .tu-scrollUp {
	width: 22px;
	text-align: right;
}

.floating-window-wrapper {
	position: relative;
}

.floating-window {
	z-index: 10003;
	position: fixed;
	max-width: 90%;
	box-shadow: 1px 2px 12px 2px #000;
	min-width: 270px;
	width: auto;
	background: #fff;
	border: 1px solid black;
	border-radius: 5px;
	padding: 0.5rem;
}

.banner-container {
	position: relative;
	width: 100%;
	display: block;
}

.popover.noarrow .arrow { 
	visibility:hidden; 
}

.numberInputWidget button {
	width: 2.5rem;
	height: 38px;
}

.numberInputWidget.fractional button {
	padding-top: 12px;
}

.numberInputWidget .numberInputWidget-input {
	height: 38px;
	width: 50px !important;
}

.numberInputWidget .numberInputWidget-input.fractional {
	width: 70px;
}

.numberInputWidget-decrement-step, .numberInputWidget-increment-step {
	position: absolute;
	padding: 1px;
	border-radius: 5px;
	background-color: #eee;
	top: 1px;
	z-index: 2;
}

.numberInputWidget-decrement-step {
	left: 3px;
}

.numberInputWidget-increment-step {
	right: 3px;
}

.numberInputWidget .input-group-prepend:hover .numberInputWidget-decrement-step,
.numberInputWidget .input-group-append:hover .numberInputWidget-increment-step {
	background-color: #fff;
}

/* fontawesome 6 fix */
.fa-lg {
	line-height: .75em;
}

.btn.disabled, .btn:disabled {
	opacity: .3 !important;
}

/* mob only */
@media (max-width: 767px) {
	.searchWidget .focusArea:focus-within + .searchWidget-searchTypes .searchTypeIcon {
		display: block;
	}
	
	.searchWidget .focusArea:focus-within + .searchWidget-searchTypes .searchTypeName {
		display: none;
	}

	/* реальная ширина будет выше, т.к. текст может выходить за предел */
	.topbar-left {
		max-width: 30%;
	}

	.topbar-right {
		background-image: linear-gradient(to right, rgba(246, 246, 246, 0), rgba(246, 246, 246, 1) 8%);
		z-index: 1;
	}

	.searchWidget .searchTypeName-text {
		max-width: 78px;
	}
}
/* /mob only */

/* desktop only */
@media (min-width: 768px) {
	.topbar {
		background-color: white;
	}

	.searchWidget .suggestions {
		z-index: 5;
	}
}

@media (min-width: 992px) {
	.modal-xl {
		max-width: 1140px;
	}
}
/* /desktop only */


/* third party */
.b-counter img {
	max-width: 100px;
}

@media (max-width: 368px) {
	.b-counter img {
		margin-top: 10px;
	}
}

/* moved from old files but relevant */

.as-progCont {
	z-index: 50000;
	display: inline-block;
	position: fixed;
	left: 48% !important;
	top: 10px
}

.as-overlay {
	background: transparent !important;
	border-radius: 5px;
	padding: 3px;
	font-weight: 700;
	color: #000;
	font-size: 12px;
}

.hide {
	display: none;
}

.modal-header .close {
	float: right;
}

/*bootstrap tooltip blinking fix*/
.tooltip {
  pointer-events: none;
}

.pwa-agree {
	border: 2px solid #bbb;
}

input[type=checkbox] {
	accent-color: #c7c5c5;
}

@media (max-width: 768px) {
	.modal.pin-to-bottom {
		display: flex !important;
		flex-direction: column;
		width: 100vw;
	}

	.modal .modal-handle {
		display: none;
	}

	.modal.pin-to-bottom .modal-handle {
		display: block;
		padding-bottom: 2.5rem;
		padding-top: 2.5rem;
		width: 80%;
		left: 10%;
		position: absolute;
		top: -55px;
		z-index: 1;
	}

	.modal.pin-to-bottom .modal-handle-line {
		width: 100px;
		height: 6px;
		background-color: white;
		border-radius: 2px;
		margin: 0 auto;
		position: relative;
		z-index: 1;
	}

	.modal.pin-to-bottom .modal-body {
		min-height: 30vh;
		max-height: 80vh;
		overflow-y: scroll;
	}

	.modal.pin-to-bottom .modal-upper-filler {
		flex-grow: 1;
		pointer-events: none;
	}

	.modal.pin-to-bottom .modal-dialog {
		margin: 0;
		pointer-events: initial;
	}
}

.underline-tum-red {
	text-decoration: underline #ca1f2f dashed 1px;
}

.modal-fullscreen {
	min-width: 100vw;
	margin: 0;
}

.modal-fullscreen .modal-content {
	height: 100vh;
	overflow: auto;
}

/* grid */

.grid-col-all {
	grid-column: 1/-1;
}

@media (min-width: 768px) {
	.grid-col-all-md {
		grid-column: 1/-1;
	}
}

.grid-columns-2 {
	grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-columns-3 {
	grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-columns-4 {
	grid-template-columns: repeat(4, minmax(0, 1fr));
}

/* /grid */